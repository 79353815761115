import {ApiClient} from "../Network";
import {CategoryModel, CategoryResultModel} from "../model/CategoryModel";

const apiClient = ApiClient.getInstance();

export const getAllCategoryResults = async () => {
    try {
        const response = await apiClient.get<CategoryModel[]>('categories');
        const fetchedCategories = response.data.data;

        return await Promise.all(fetchedCategories.map(async (item) => {
            let groupName = 'No Group';
            let typeName = 'No Type';

            if (item.group_id) {
                const group = await apiClient.getGroup(item.group_id)
                groupName = group?.name || 'No Group';
            }

            if (item.type_id) {
                const type = await apiClient.getType(item.type_id)
                typeName = type?.name || 'No Type';

                if (type?.group_id) {
                    const group = await apiClient.getGroup(type.group_id)
                    groupName = group?.name || 'No Group';
                    item.group_id = group?.id || null
                }
            }

            return {
                id: item.id,
                name: item.name,
                group_id: item.group_id,
                type_id: item.type_id,
                group_name: groupName,
                type_name: typeName,
                is_premium: item.is_premium,
                language: item.language
            };
        }))

    } catch (error) {
        console.error('Error fetching component types', error);
    }
};

export const getCategoryResultsFromCategories = async (categories: CategoryModel[]) => {
    try {
        return await Promise.all(categories.map(async (item) => {
            let groupName = 'No Group';
            let typeName = 'No Type';

            if (item.group_id) {
                const group = await apiClient.getGroup(item.group_id)
                groupName = group?.name || 'No Group';
            }

            if (item.type_id) {
                const type = await apiClient.getType(item.type_id)
                typeName = type?.name || 'No Type';

                if (type?.group_id) {
                    const group = await apiClient.getGroup(type.group_id)
                    groupName = group?.name || 'No Group';
                    item.group_id = group?.id || null
                }
            }

            return {
                id: item.id,
                name: item.name,
                group_id: item.group_id,
                type_id: item.type_id,
                group_name: groupName,
                type_name: typeName,
                is_premium: item.is_premium,
                language: item.language
            };
        }))

    } catch (error) {
        console.error('Error fetching component types', error);
    }
};