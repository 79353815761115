import React, { ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import CategoryGroupComponent from './component/CategoryGroupComponent';
import CategoryTypeComponent from './component/CategoryTypeComponent';
import CategoryComponent from './component/CategoryComponent';
import AuthorsComponent from './component/AuthorComponent';
import CategoryAuthorsComponent from './component/CategoryAuthorComponent';
import QuoteComponent from "./component/QuoteComponent";
import LoginComponent from "./component/LoginComponent";

import { AppContainer, HorizontalList, ListItem, StyledLink } from "./styles";
import axios from "axios";


axios.defaults.headers.common['Admin-Token'] = 'admin_zxc_123_123_123123123_321';

interface PrivateRouteProps {
    children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const auth = localStorage.getItem('auth');
    return auth ? <>{children}</> : <Navigate to="/admin/login" />;
};

const App: React.FC = () => {
    return (
        <AppContainer>
            <Router>
                <div>
                    <h1>Motivation Quotes Backend Management</h1>
                    <nav>
                        <HorizontalList>
                            <ListItem><StyledLink to="/admin/category-groups">Category Groups</StyledLink></ListItem>
                            <ListItem><StyledLink to="/admin/category-types">Category Types</StyledLink></ListItem>
                            <ListItem><StyledLink to="/admin/categories">Categories</StyledLink></ListItem>
                            <ListItem><StyledLink to="/admin/category-authors">Category Authors</StyledLink></ListItem>
                            <ListItem><StyledLink to="/admin/authors">Authors</StyledLink></ListItem>
                            <ListItem><StyledLink to="/admin/quotes">Quotes</StyledLink></ListItem>
                        </HorizontalList>
                    </nav>
                    <Routes>
                        <Route path="/admin/login" element={<LoginComponent />} />
                        <Route path="/admin/category-groups" element={<PrivateRoute><CategoryGroupComponent /></PrivateRoute>} />
                        <Route path="/admin/category-types" element={<PrivateRoute><CategoryTypeComponent /></PrivateRoute>} />
                        <Route path="/admin/categories" element={<PrivateRoute><CategoryComponent /></PrivateRoute>} />
                        <Route path="/admin/authors" element={<PrivateRoute><AuthorsComponent /></PrivateRoute>} />
                        <Route path="/admin/category-authors" element={<PrivateRoute><CategoryAuthorsComponent /></PrivateRoute>} />
                        <Route path="/admin/quotes" element={<PrivateRoute><QuoteComponent /></PrivateRoute>} />
                        <Route path="*" element={<Navigate to="/admin/login" />} />
                    </Routes>
                </div>
            </Router>
        </AppContainer>
    );
};

export default App;
