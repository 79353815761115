import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "./Constants";
import {CategoryGroupModel} from "./model/CategoryGroupModel";
import {CategoryTypeModel} from "./model/CategoryTypeModel";

export interface ApiResponse<T> {
    data: T;
    error: string | null;
}

export class ApiClient {
    private static instance: ApiClient;
    private baseUrl: string;
    private headers: Record<string, string>;

    private constructor(baseUrl: string, adminToken: string) {
        this.baseUrl = baseUrl;
        this.headers = {
            'Admin-Token': adminToken,
        };
    }

    static getInstance(): ApiClient {
        if (!ApiClient.instance) {
            ApiClient.instance = new ApiClient(BASE_URL, 'admin_zxc_123_123_123123123_321');
        }
        return ApiClient.instance;
    }

    async get<T = any>(url: string) {
        return await axios.get<ApiResponse<T>>(this.baseUrl + url, {
            headers: this.headers
        });
    }

    async post<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
        const response = await axios.post<ApiResponse<T>>(this.baseUrl + url, data, {
            ...config,
            headers: {
                ...this.headers,
                ...config?.headers
            }
        });
        return response.data;
    }

    async put<T = any, D = any>(url: string, data?: D) {
        return await axios.put<ApiResponse<T>>(this.baseUrl + url, data, {
            headers: this.headers
        });
    }

    async del<T = any>(url: string) {
        return await axios.delete<ApiResponse<T>>(this.baseUrl + url, {
            headers: this.headers
        });
    }


    getGroup = async (id: number) => {
        try {
            const groupResponse = await this.get<CategoryGroupModel>(`category_group/${id}`);
            return groupResponse.data.data
        } catch (error) {
            console.error(`Error fetching group for group_id`, error);
        }
    }

    getType = async (id: number) => {
        try {
            const typeResponse = await this.get<CategoryTypeModel>(`category_type/${id}`);
            return typeResponse.data.data;
        } catch (error) {
            console.error(`Error fetching type for type_id:`, error);
        }
    }
    getAuthor = async (id: string) => {
        try {
            const typeResponse = await this.get<CategoryTypeModel>(`author/${id}`);
            return typeResponse.data.data;
        } catch (error) {
            console.error(`Error fetching type for type_id:`, error);
        }
    }
}