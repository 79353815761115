import React, {useState, useEffect} from 'react';
import {Container, Title, Input, Button, List, ListItem, ErrorMessage} from '../styles';
import {ApiClient} from "../Network";
import {QuoteModel, QuoteResultModel} from "../model/QuoteModel";
import {getCategoryResultsFromCategories} from "../usecase/GetCategoryResultsUseCase";
import {AuthorModel} from "../model/AuthorModel";
import {CategoryResultModel} from "../model/CategoryModel";
import {CategoryItem} from "./CategoryComponent";
import Switch from "react-switch";

const apiClient = ApiClient.getInstance();

const QuoteComponent: React.FC = () => {
    const [results, setResults] = useState<QuoteResultModel[]>([]);
    const [body, setBody] = useState<string>('');
    const [id, setId] = useState<number | null>(null);
    const [author_id, setAuthorId] = useState<string>('');
    const [category_ids, setCategoryIds] = useState<number[]>([]);
    const [category_ids_str, setCategoryIdsStr] = useState(JSON.stringify(category_ids));
    const [language, setLanguage] = useState<string>('ru');
    const [isRu, setIsRu] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiClient.get<QuoteModel[]>('admin_quotes');
            const results = await Promise.all(response.data.data.map(async (item) => {

                const categoryResults = await getCategoryResultsFromCategories(item.categories);

                return {
                    id: item.id,
                    body: item.body,
                    author: item.author,
                    language: item.language,
                    categories: categoryResults || []
                };
            }))

            setResults(results)
        } catch (error) {
            console.error('Error fetching quotes', error);
            setError('Error fetching quotes')
        }
    };

    // const getData = async () => {
    //     try {
    //         const response = await apiClient.get<QuoteModel[]>('admin_quotes');
    //         const data = response.data.data;
    //
    //         const results = await Promise.all(data.map(async (model) => {
    //
    //             return {
    //                 id: model.id,
    //                 name: model.name,
    //                 group_id: model.group_id,
    //                 group_name: groupResponse.data.data.name,
    //             };
    //         }));
    //
    //         setResults(results);
    //
    //     } catch (error) {
    //         console.error('Error fetching component types', error);
    //         setError('Error fetching component type');
    //     }
    // };

    const create = async () => {
        try {
            await apiClient.post<String>('quote', {
                body,
                author_id,
                category_ids,
                language
            });
            getData()
            setBody('');
            setCategoryIds([]);
            setAuthorId('');
            setId(null);
        } catch (error) {
            console.error('Error creating quotes', error);
            setError('Error creating quotes')
        }
    };

    const update = async () => {
        if (id === null) return;
        try {
            await apiClient.put<String>(`quotes/${id}`, {
                    body,
                    author_id,
                    category_ids,
                language
                }
            );
            getData();
            setBody('');
            setCategoryIds([]);
            setAuthorId('');
            setId(null);
        } catch (error) {
            console.error('Error updating author', error);
            setError('Error updating author')

        }
    };

    const deleteAuthor = async (id: number) => {
        try {
            await apiClient.del<String>(`quotes/${id}`);
            getData();
        } catch (error) {
            console.error('Error deleting author', error);
            setError('Error deleting author')

        }
    };

    const handleChangeCategoryIds = ({event}: { event: any }) => {
        const value = event.target.value;
        setCategoryIdsStr(value);

        try {
            const parsedArray = JSON.parse(value);
            if (Array.isArray(parsedArray)) {
                setCategoryIds(parsedArray);
            }
        } catch (e) {
            // Handle parsing error if needed
        }
    };

    return (
        <Container>
            <Title>Quotes</Title>
            <Input
                type="text"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Body"
            />
            <Input
                type="text"
                value={category_ids_str}
                onChange={(e) => handleChangeCategoryIds({event: e})}
                placeholder="Category Ids"
            />
            <Input
                type="text"
                value={author_id}
                onChange={(e) => setAuthorId(e.target.value)}
                placeholder="Author Id"
            />
            <Switch
                checked={isRu}
                onChange={
                    () => {
                        let lang = "ru"

                        if (!isRu) {
                            lang = "en"
                        }

                        setLanguage(lang)
                        setIsRu(!isRu)
                    }
                }
                title={language}
                name={language}
            />
            Lang {language}
            <Button onClick={id === null ? create : update}>
                {id === null ? 'Create' : 'Update'}
            </Button>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <List>
                {Array.isArray(results) && results.map((item) => (

                    <ListItem key={item.id}>
                        <div className="categories-list">
                            ID: {item.id}<br/> <br/>Body: {item.body}<br/>Author {item.author.id} {item.author.name} {item.author.bio}<br/>

                            Categories: <CategoriesList categories={item.categories}/>

                        </div>

                        <div>
                            <Button onClick={() => {
                                setBody(item.body);
                                setId(item.id);
                                setAuthorId(item.author.id)
                            }}>Edit</Button>
                            <Button onClick={() => deleteAuthor(item.id)}>Delete</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

const CategoriesList: React.FC<{ categories: CategoryResultModel[] }> = ({categories}) => (
    <div className="categories-list">
        {categories.map((category) => (
            <CategoryItem key={category.id} category={category}/>
        ))}
    </div>
);

export default QuoteComponent;

export {}; // Add this line to ensure the file is treated as a module
