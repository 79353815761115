import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Container, Title, Input, Button, List, ListItem, ErrorMessage} from '../styles';
import {BASE_URL} from "../Constants";
import {ApiClient, ApiResponse} from "../Network";
import {CategoryTypeModel, CategoryTypeResultModel} from "../model/CategoryTypeModel";
import {CategoryGroupModel} from "../model/CategoryGroupModel";
import {CategoryResultModel} from "../model/CategoryModel";
import Switch from "react-switch";

const apiClient = ApiClient.getInstance();

const CategoryTypeComponent: React.FC = () => {
    const [results, setResults] = useState<CategoryTypeResultModel[]>([]);
    const [name, setName] = useState<string>('');
    const [group_id, setGroup_id] = useState<number | null>(null);
    const [id, setId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [language, setLanguage] = useState<string>('ru');
    const [isRu, setIsRu] = useState<boolean>(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiClient.get<CategoryTypeModel[]>('category_types');
            const fetchedCategoryTypes = response.data.data;

            const results = await Promise.all(fetchedCategoryTypes.map(async (type) => {
                const groupResponse = await apiClient.get<CategoryGroupModel>(`category_group/${type.group_id}`);
                return {
                    id: type.id,
                    name: type.name,
                    group_id: type.group_id,
                    group_name: groupResponse.data.data.name,
                    language: type.language
                };
            }));

            setResults(results);

        } catch (error) {
            console.error('Error fetching component types', error);
            setError('Error fetching component type');
        }
    };

    const createCategoryType = async () => {
        try {
            await apiClient.post<String>('category_type', {
                name,
                group_id,
                language
            });
            getData()
            clearFields()
        } catch (error) {
            console.error('Error creating component type', error);
            setError('Error creating component type')
        }
    };

    const updateCategoryType = async () => {
        if (id === null) return;
        try {
            await apiClient.put<String>(`category_type/${id}`, {
                    name,
                    group_id,
                    language
                }
            );
            getData()
            clearFields()
        } catch (error) {
            console.error('Error updating component type', error);
            setError('Error updating component type')
        }
    };

    const deleteCategoryType = async (id: number) => {
        try {
            await apiClient.del<String>(`category_type/${id}`);
            getData();
        } catch (error) {
            console.error('Error deleting component type', error);
            setError('Error deleting component type')

        }
    };

    const clearFields = () => {
        setName('');
        setGroup_id(null);
        setId(null);
    }

    return (
        <Container>
            <Title>Category Types</Title>
            <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Category Type Name"
            />
            <Input
                type="number"
                value={group_id || ''}
                onChange={(e) => setGroup_id(Number(e.target.value))}
                placeholder="Group ID"
            />
            <Switch
                checked={isRu}
                onChange={
                    () => {
                        let lang = "ru"

                        if (!isRu) {
                            lang = "en"
                        }

                        setLanguage(lang)
                        setIsRu(!isRu)
                    }
                }
                title={language}
                name={language}
            />
            Lang {language}
            <Button onClick={id === null ? createCategoryType : updateCategoryType}>
                {id === null ? 'Create' : 'Update'}
            </Button>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <List>
                {Array.isArray(results) && results.map((item) => (
                    <ListItem key={item.id}>

                        <CategoryTypeItem key={item.id} categoryType={item}/>

                        <div>
                            <Button onClick={() => {
                                setName(item.name);
                                setGroup_id(item.group_id);
                                setId(item.id);
                                setLanguage(item.language)
                            }}>Edit</Button>
                            <Button onClick={() => deleteCategoryType(item.id)}>Delete</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

const CategoryTypeItem: React.FC<{ categoryType: CategoryTypeResultModel }> = ({categoryType}) => (
    <div>
        {categoryType.group_id && (
            <>
                <strong>Group:</strong> {categoryType.group_name} (ID: {categoryType.group_id})<br/>
            </>
        )}

        <ul>
            <li><strong>Type:</strong> {categoryType.name} (ID: {categoryType.id})</li>
        </ul>

    </div>
);

export default CategoryTypeComponent;

export {}; // Add this line to ensure the file is treated as a module
