import styled from 'styled-components';
import {Link} from "react-router-dom";

export const Container = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const AppContainer = styled.div`
  margin: 20px;
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
`;

export const Input = styled.input`
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;

    /* Убираем стрелочки в полях типа number для браузеров на базе Webkit (Chrome, Safari, Edge) */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Убираем стрелочки в полях типа number для Firefox */
    -moz-appearance: textfield;

    /* Обязательно для корректного отображения placeholder */
    &::placeholder {
        color: #aaa;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: #0056b3;
        color: white;
    }
`;

export const Button = styled.button`
    padding: 10px 15px;
    margin: 4px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;


export const HorizontalList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex; /* Используем flexbox для горизонтального списка */
  gap: 10px; /* Расстояние между элементами списка */
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

export const ButtonGroup = styled.div`

`;


export {};