import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {Input, Button, ErrorMessage} from '../styles';
import {ApiResponse} from "../Network";
import {BASE_URL} from "../Constants";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        const authHeader = 'Basic ' + btoa(`${username}:${password}`);
        console.log(authHeader)
        try {
            await axios.get<ApiResponse<String>>(BASE_URL + 'auth/admin', {
                headers: {
                    'Authorization': authHeader,
                }
            });

            localStorage.setItem('auth', authHeader);

            navigate('/dashboard');
        } catch (error) {
            setError('Invalid credentials. Please try again.');
        }
    };

    return (
        <div>
            <h2>Login</h2>
            <div>
                <Input
                    type="text"
                    value={username}
                    placeholder={'Username:'}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div>
                <Input
                    type="password"
                    value={password}
                    placeholder={'Password:'}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <Button onClick={handleLogin}>Login</Button>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    );
};

export default Login;
