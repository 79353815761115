import React, {useState, useEffect} from 'react';
import {Container, Title, Input, Button, List, ListItem, ErrorMessage } from '../styles';
import Switch from "react-switch";
import {ApiClient, ApiResponse} from "../Network";
import {CategoryAuthorModel, CategoryAuthorResultModel} from "../model/CategoryAuthorModel";
import {CategoryModel, CategoryResultModel} from "../model/CategoryModel";

var apiClient = ApiClient.getInstance()

const CategoryAuthorComponent: React.FC = () => {
    const [results, setResults] = useState<CategoryAuthorResultModel[]>([]);
    const [author_id, setAuthor_id] = useState<string>('');
    const [type_id, setType_id] = useState<number | null>(null);
    const [group_id, setGroup_id] = useState<number | null>(null);
    const [id, setId] = useState<number | null>(null);
    const [is_premium, setIsPremium] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await apiClient.get<CategoryAuthorModel[]>('category_authors');
            const fetchedData = response.data.data;

            const results = await Promise.all(fetchedData.map(async (item) => {
                let groupName = 'No Group';
                let typeName = 'No Type';

                if (item.group_id) {
                    const group = await apiClient.getGroup(item.group_id)
                    groupName = group?.name || 'No Group';
                }

                if (item.type_id) {
                    const type = await apiClient.getType(item.type_id)
                    typeName = type?.name || 'No Type';

                    if (type?.group_id) {
                        const group = await apiClient.getGroup(type.group_id)
                        groupName = group?.name || 'No Group';
                        item.group_id = group?.id || null
                    }
                }

                const author = await apiClient.getAuthor(item.author_id)

                return {
                    id: item.id,
                    group_id: item.group_id,
                    type_id: item.type_id,
                    group_name: groupName,
                    type_name: typeName,
                    author_id: item.author_id,
                    author_name: author?.name || 'No Author Name',
                    is_premium: item.is_premium
                };
            }));

            setResults(results);

        } catch (error) {
            console.error('Error fetching component types', error);
            setError('Error fetching component type');
        }
    };


    const create = async () => {
        try {
            await apiClient.post<String>('category_author', {
                author_id,
                type_id,
                group_id,
                is_premium
            });
            await getData()
            setAuthor_id('');
            setType_id(null);
            setGroup_id(null);
        } catch (error) {
            console.error('Error creating component author', error);
            setError('Error creating component author')
        }
    };

    const update = async () => {
        if (id === null) return;
        try {
            await apiClient.put<String>(`category_author/${id}`, {
                    author_id,
                    type_id,
                    group_id,
                    is_premium
                }
            );
            await getData();
            setAuthor_id('');
            setType_id(null);
            setId(null);
            setGroup_id(null);
        } catch (error) {
            console.error('Error updating component author', error);
            setError('Error updating component author')

        }
    };

    const deleteCategoryType = async (id: number) => {
        try {
            await apiClient.del<String>(`category_author/${id}`);
            await getData();
        } catch (error) {
            console.error('Error deleting component author', error);
            setError('Error deleting component author')

        }
    };

    return (
        <Container>
            <Title>Category Authors</Title>
            <Input
                type="text"
                value={author_id}
                onChange={(e) => setAuthor_id(e.target.value)}
                placeholder="Author ID"
            />
            <Input
                type="number"
                value={type_id || ''}
                onChange={(e) => setType_id(Number(e.target.value))}
                placeholder="Type ID"
            />
            <Input
                type="number"
                value={group_id || ''}
                onChange={(e) => setGroup_id(Number(e.target.value))}
                placeholder="Group ID"
            />
            <Switch
                checked={is_premium}
                onChange={() => setIsPremium(!is_premium)}
                title={ 'is Premium' }
            />
            <Button onClick={id === null ? create : update}>
                {id === null ? 'Create' : 'Update'}
            </Button>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <List>
                {Array.isArray(results) && results.map((item) => (
                    <ListItem key={item.id}>

                        <CategoryAuthorItem key={item.id} author={item}/>

                        <div>
                            <Button onClick={() => {
                                setAuthor_id(item.author_id);
                                setType_id(item.type_id);
                                setId(item.id);
                                setGroup_id(item.group_id)
                                setIsPremium(item.is_premium)
                            }}>Edit</Button>
                            <Button onClick={() => deleteCategoryType(item.id)}>Delete</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

const CategoryAuthorItem: React.FC<{ author: CategoryAuthorResultModel }> = ({author}) => (
    <div>
        {author.group_id && (
            <>
                <strong>Group:</strong> {author.group_name} (ID: {author.group_id})<br/>
            </>
        )}

        {author.type_id && (
            <>
                <ul>
                    <li><strong>Type:</strong> {author.type_name} (ID: {author.type_id})</li>

                    <ul>
                        <li><strong>Author: </strong> {author.author_name} (Author ID: {author.author_id})
                            (ID: {author.id}) (IsPremium: {author.is_premium.toString()})
                        </li>
                    </ul>
                </ul>
            </>
        )}

        {!author.type_id && (
            <>
                <ul>
                    <li><strong>Author: </strong> {author.author_name} (Author ID: {author.author_id}) (ID: {author.id}) (IsPremium: {author.is_premium.toString()})</li>
                </ul>
            </>
        )}
    </div>
);


export default CategoryAuthorComponent;

export {};
