import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Container, Title, Input, Button, List, ListItem, ErrorMessage} from '../styles';
import {BASE_URL} from "../Constants";
import {ApiClient, ApiResponse} from "../Network";
import app from "../App";
import {CategoryGroupModel} from "../model/CategoryGroupModel";
import Switch from "react-switch";

const apiClient = ApiClient.getInstance();

const CategoryGroupComponent: React.FC = () => {
    const [categoryGroups, setCategoryGroups] = useState<CategoryGroupModel[]>([]);
    const [name, setName] = useState<string>('');
    const [id, setId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [language, setLanguage] = useState<string>('ru');
    const [isRu, setIsRu] = useState<boolean>(false);

    useEffect(() => {
        fetchCategoryGroups();
    }, []);

    const fetchCategoryGroups = async () => {
        try {
            console.log("Fetching component groups...");
            const response = await apiClient.get<CategoryGroupModel[]>('category_groups');
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setCategoryGroups(response.data.data);
                setError(null);
                console.log("Fetched component groups: ", response.data.data);
            }
        } catch (error) {
            console.error('Error fetching component groups', error);
            setError('Error fetching component groups');
        }
    };

    const createCategoryGroup = async () => {
        try {
            const response = await apiClient.post<CategoryGroupModel>('category_group', {name, language});
            fetchCategoryGroups()
            setName('');
        } catch (error) {
            console.error('Error creating component group', error);
            setError('Error creating component group')
        }
    };

    const updateCategoryGroup = async () => {
        if (id === null) return;
        try {
            await apiClient.put<String>(`category_group/${id}`, {name, language});
            fetchCategoryGroups();
            setName('');
            setId(null);
        } catch (error) {
            console.error('Error updating component group', error);
            setError('Error updating component group')
        }
    };

    const deleteCategoryGroup = async (id: number) => {
        try {
            await apiClient.del<String>(`category_group/${id}`);
            fetchCategoryGroups();
        } catch (error) {
            console.error('Error deleting component group', error);
            setError('Error deleting component group')
        }
    };

    return (
        <Container>
            <Title>Category Groups</Title>
            <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Category Group Name"
            />
            <Switch
                checked={isRu}
                onChange={
                    () => {
                        let lang = "ru"

                        if (!isRu) {
                            lang = "en"
                        }

                        setLanguage(lang)
                        setIsRu(!isRu)
                    }
                }
                title={language}
                name={language}
            />
            Lang {language}
            <Button onClick={id === null ? createCategoryGroup : updateCategoryGroup}>
                {id === null ? 'Create' : 'Update'}
            </Button>

            {error && <ErrorMessage>{error}</ErrorMessage>}

            <List>
                {Array.isArray(categoryGroups) && categoryGroups.map((group) => (
                    <ListItem key={group.id}>
                        (ID = {group.id}) {group.name}
                        <div>
                            <Button onClick={() => {
                                setName(group.name);
                                setId(group.id);

                            }}>Edit</Button>
                            <Button onClick={() => deleteCategoryGroup(group.id)}>Delete</Button>
                        </div>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default CategoryGroupComponent;

export {}; // Add this line to ensure the file is treated as a module
